import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'

import { Article, OrangeLayout } from '../../components'
import config from '../../../config'


const AllBlogPosts = ({ data: { allMdx } }) => {
  const { nodes, totalCount } = allMdx
  const subline = `${totalCount} post${totalCount === 1 ? '' : 's'}`
  const fullbyline = <>{subline} (<Link to="/blog/categories">By category</Link>)</>

  return <OrangeLayout
      title={`All blog posts`}
      byline={fullbyline}
    >
    <Helmet title={`All blog posts | ${config.siteTitle}`} />
    {nodes.map(post => (
      <Article
        title={post.frontmatter.title}
        date={post.fields.date}
        excerpt={post.excerpt}
        timeToRead={post.timeToRead}
        slug={post.fields.slug}
        categories={post.frontmatter.categories}
        key={post.fields.slug}
      />
    ))}
  </OrangeLayout>;
}

export default AllBlogPosts

AllBlogPosts.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

export const postQuery = graphql`
query AllBlogPostsPage {
  allMdx(sort: {fields: [fields___date], order: DESC}, filter: {fields: {slug: {glob: "/blog/*"}}}) {
    totalCount
    nodes {
      frontmatter {
        title
        categories
      }
      fields {
        slug
        date(formatString: "MM/DD/YYYY")
      }
      excerpt(pruneLength: 200)
      timeToRead
    }
  }
}

`
